import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./Category.css";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '80%',
    maxWidth: '500px',
    fontfamily: 'Fraunces',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f9fde5', 
    border: 'none',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', 
  },
};

const Category = () => {
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      toast.error("Please select an image");
      return;
    }

    try {
      setLoading(true);
      const imageName = `${uuidv4()}_${image.name}`;
      const imageRef = ref(storage, `images/${imageName}`);

      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      const category = document.getElementById("category").value;
      const response = await axios.post(
        `${baseUrl}/api/upload`,
        { category, image: imageUrl },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data, 'Category');
      toast.success("Category Added");

      setPreviewImage(null);
      setImage(null);
      setCategory("");
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error(error);
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      setLoadingCategories(true);
      const response = await axios.get(`${baseUrl}/api/all`);
      console.log(response.data, "kk");
      setData(response.data);
      setLoadingCategories(false);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/${id}`);
      console.log(response.data, 'Deleted');
      toast.success("Category Deleted");
      setData(data.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setPreviewImage("");
    setImage(null);
    setCategory("");
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setNewPreviewImage(null);
    }
    setNewImage(file);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      let imageUrl = editCategory.image;

      if (newImage) {
        const imageName = `${uuidv4()}_${newImage.name}`;
        const imageRef = ref(storage, `images/${imageName}`);
        await uploadBytes(imageRef, newImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      const response = await axios.put(`${baseUrl}/api/${editCategory._id}`, {
        category: editCategory?.category,
        image: imageUrl,
      });

      console.log(response.data, "Category Updated");
      toast.success("Category Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating category");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    console.log(value, 'value');
    try {
      const response = await axios.put(`${baseUrl}/api/onoff/${id}`, {
        "onoff": value
      });
      console.log(response.data);
      fetchDetails();

    } catch (error) {
      console.error('Error toggling category', error);
    }
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {loadingCategories ? (
          <p >Loading...</p>
        ) : data?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Category-Name
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Image
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  ON/OFF
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Edit
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "10px" }}>{item.category}</td>
                  <td style={{ padding: "10px" }}>
                    <img src={item.image} alt="Category" style={{ width: "50px" }} />
                  </td>
                  {item?.onoff==="ON"?(<>
                  <td style={{ padding: "10px"}}>
                    <button style={{ backgroundColor:'green' }} onClick={() => handleToggleClick('OFF',item?._id)}>ON</button>
                  </td>
                  </>):(<>
                    <td style={{ padding: "10px" }}>
                    <button style={{ backgroundColor:'red' }} onClick={() => handleToggleClick('ON',item?._id)} >OFF</button>
                  </td>
                  </>)}
                  <td style={{ padding: "10px" }}>
                    <button className="edit-button" onClick={() => openModal(item)}>Edit</button>
                  </td>
                  <td style={{ padding: "10px" }}>
                    <button className="delete-button" onClick={() => handleDelete(item?._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <button onClick={openAddModal} className="fab-button">Add Category</button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Category"
        ariaHideApp={false}
      >
        <h2>Edit Category</h2>
        <form onSubmit={handleEditSubmit}>
          <input
            type="text"
            placeholder="Enter category"
            id="editCategory"
            name="editCategory"
            value={editCategory.category}
           
            onChange={(event) => {
              const value = event.target.value;
              if (/^[a-zA-Z\s]*$/.test(value)) {
                setEditCategory({ ...editCategory, category: value });
              }
            }}
          />
          <input type="file" id="newImage"   accept="image/*" onChange={handleNewImageChange} />
          {newPreviewImage && (
            <img
              src={newPreviewImage}
              alt="Image preview"
              style={{ width: 100, marginLeft: 10 }}
            />
          )}
          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </button>
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        style={customStyles}
        contentLabel="Add Category"
        ariaHideApp={false}
      >
        <h2>Add Category</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter category"
            id="category"
            name="category"
            value={category}
            onChange={(event) => {
              const value = event.target.value;
              if (/^[a-zA-Z\s]*$/.test(value)) {  
                setCategory(value); 
              }
            }}          />
          <input type="file" id="image" accept="image/*"  onChange={handleImageChange} />
          {previewImage && (
            <img
              src={previewImage}
              alt="Image preview"
              style={{ width: 100, marginLeft: 10 }}
            />
          )}
          <div className="add-category-btn-sec">

          <button className="add-cat-subt-btn" type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Submit"}
          </button>
          <button className="add-cat-close-btn" type="button" onClick={closeAddModal}>
            Close
          </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Category;
