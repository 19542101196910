import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "../Category/Category.css";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '80%',
    maxWidth: '500px',
    fontfamily: 'Fraunces',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f9fde5', 
    border: 'none',
    padding: '20px',
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent overlay background
  },
};

const Category = () => {
  const [variationname, setVariationname] = useState("");
  const [heading, setHeading] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [attributes, setAttributes] = useState([{ name: "" }]);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false); // State for view modal
  const [selectedProduct, setSelectedProduct] = useState(null); // State to hold selected product details
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [data, setData] = useState([]);
  const [Variationsdata, setVariationsdata] = useState([]);
  console.log(Variationsdata, "Variationsdata");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);
      const variationname = document.getElementById("variationname").value;

      const response = await axios.post(`${baseUrl}/api/variation`, {
        category: selectedCategory,
        variationname,
        attributes,
      });

      console.log(response.data, "Category");
      toast.success("Variation Added");
      fetchVariations();
      setPreviewImage(null);
      setCategory("");
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/all`);
      console.log(response.data, "kk");
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
  const fetchVariations = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/variation/all`);
      console.log(response.data, "kk");
      setVariationsdata(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    fetchVariations();
  }, []);
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/variation/${id}`);
      console.log(response.data, "Deleted");
      toast.success("Variation Deleted");
      setData(data.filter((item) => item._id !== id));
      fetchDetails();
      fetchVariations();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setPreviewImage("");
    setImage(null);
    setCategory("");
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setNewPreviewImage(null);
    }
    setNewImage(file);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const response = await axios.put(
        `${baseUrl}/api/variation/edit/${editCategory._id}`,
        {
          category: selectedCategory,
          variationname: variationname,
        }
      );
      fetchVariations();
      console.log(response.data, "Category Updated");
      toast.success("Variation Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating category");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/variation/onoff/${id}`, {
        onoff: value,
      });
      console.log(response.data);
      fetchDetails();
      fetchVariations();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };

  const handleAttributeChange = (index, field, value) => {
    const newAttributes = [...attributes];
    newAttributes[index][field] = value;
    setAttributes(newAttributes);
    console.log(newAttributes,'newAttributes');
  };
  
  const addAttribute = () => {
    setAttributes([...attributes, { name: ""}]);
  };
  
  const removeAttribute = (index) => {
    const newAttributes = attributes.filter((_, i) => i !== index);
    setAttributes(newAttributes);
  };
  const handleViewClick = (product) => {
    setSelectedProduct(product);
    setViewModalIsOpen(true); // Open view modal
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {Variationsdata?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <div className="scrollable-box">
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                    Category-Name
                  </th>
                  <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                    Variantname
                  </th>  
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                    }}
                    onClick={(item) => handleViewClick(item)}
                  >
                    <FontAwesomeIcon icon={faEye} /> View
                  </th>
                  <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                    ON/OFF
                  </th>
                  <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                    Edit
                  </th>
                  <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                    Delete
                  </th>
                </tr>
              </thead>
              <tbody>
                {Variationsdata?.map((item) => (
                  <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                    <td style={{ padding: "10px" }}>
                      {item?.category?.category}
                    </td>
                    <td style={{ padding: "10px" }}>{item?.variationname}</td>
                  <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => handleViewClick(item)}
                        style={{ cursor: "pointer", color: "#007bff" }}
                      />
                    </td>
                    {item?.onoff === "ON" ? (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() => handleToggleClick("OFF", item?._id)}
                        >
                          ON
                        </button>
                      </td>
                    ) : (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() => handleToggleClick("ON", item?._id)}
                        >
                          OFF
                        </button>
                      </td>
                    )}
                    <td style={{ padding: "10px" }}>
                      <button className="edit-button" onClick={() => openModal(item)}>Edit</button>
                    </td>
                    <td style={{ padding: "10px" }}>
                      <button className="delete-button" onClick={() => handleDelete(item?._id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button onClick={openAddModal} className="fab-button">
          Add Variation
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Category"
        ariaHideApp={false}
      >
        <h2>Edit Variation</h2>
        <form onSubmit={handleEditSubmit}>
          <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <option value="">Select a category</option>
            {data?.map((category) => (
              <option key={category._id} value={category._id}>
                {category.category}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Enter heading"
            id="heading"
            name="heading"
            value={heading}
            onChange={(event) => setHeading(event.target.value)}
          />
          <input
            type="text"
            placeholder="Enter variationname"
            id="variationname"
            name="variationname"
            value={variationname}
            onChange={(event) => setVariationname(event.target.value)}
          />
          {attributes.map((attribute, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <input
                type="text"
                placeholder="Attribute Name"
                value={attribute.name}
                onChange={(e) => handleAttributeChange(index, "name", e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <button className="variation-remove-attri-btn" onClick={() => removeAttribute(index)} style={{ marginLeft: "10px" }}>
                Remove
              </button>
            </div>
          ))}
          <button className="variation-add-attri-btn" type="button" onClick={addAttribute} style={{ marginBottom: "10px" }}>
            Add Attribute
          </button>
          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </button>
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>
      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        style={customStyles}
        contentLabel="Add Category"
        ariaHideApp={false}
      >
        <h2>Add Variation</h2>
        <form onSubmit={handleSubmit}>
          <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <option value="">Select a category</option>
            {data?.map((category) => (
              <option key={category._id} value={category._id}>
                {category.category}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Enter variation Name"
            id="variationname"
            name="variationname"
            value={variationname}
            onChange={(event) => setVariationname(event.target.value)}
          />
          {attributes?.map((attribute, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <input
                type="text"
                placeholder="Attribute Name"
                value={attribute.name}
                onChange={(e) => handleAttributeChange(index, "name", e.target.value)}
                style={{ marginRight: "10px" }}
              />
              <button className="variation-remove-attri-btn"  onClick={() => removeAttribute(index)} style={{ marginLeft: "10px" }}>
                Remove
              </button>
            </div>
          ))}
          <button className="variation-add-attri-btn"   type="button" onClick={addAttribute} style={{ marginBottom: "10px" }}>
            Add Attribute
          </button>
          <div className="add-category-btn-sec">

          <button className="add-cat-subt-btn"  type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Submit"}
          </button>
          <button className="add-cat-close-btn" type="button" onClick={closeAddModal}>
            Close
          </button>
          </div>
        </form>
      </Modal>

      {/* View Modal */}
      <Modal
        isOpen={viewModalIsOpen}
        onRequestClose={() => setViewModalIsOpen(false)}
        style={customStyles}
        contentLabel="View Product Details"
        ariaHideApp={false}
      >
        <h2>Product Details</h2>
        {selectedProduct && (
          <div>
            <p>Category: {selectedProduct.category?.category}</p>
            <p>Variation Name: {selectedProduct.variationname}</p>
            {selectedProduct.attributes.length > 0 ? (
        <div>
          <p>Attributes:</p>
          <ul>
            {selectedProduct.attributes.map((attribute, index) => (
              <li key={index}>{attribute.name}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No attributes found.</p>
      )}

          </div>
        )}
        <button onClick={() => setViewModalIsOpen(false)}>Close</button>
      </Modal>
    </div>
  );
};

export default Category;
