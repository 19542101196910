import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Newsubcategory.css';
import Navbar from '../../Components/Navbar';
import { storage } from '../Firebase/Config';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
const baseUrl = process.env.REACT_APP_BASEURL;

const Newsubcategory = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [newsubcategories, setNewsubcategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [newsubcategory, setNewsubcategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [image, setImage] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for showing modal

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setPreviewImage(reader.result);
      });

      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }

    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      toast.error('Please select an image');
      return;
    }

    try {
      setLoading(true);
      const imageName = `${uuidv4()}_${image.name}`;
      const imageRef = ref(storage, `images/${imageName}`);

      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      const category = selectedCategory;
      const subcategory = selectedSubCategory;
      const newsubcategory = document.getElementById('newsubcategory').value;

      const response = await axios.post(
        `${baseUrl}/api/newsubcategory/`,
        { category, subcategory, newsubcategory, image: imageUrl },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      toast.success('Subcategory Added');

      setPreviewImage(null);
      setImage(null);
      setLoading(false);
      setShowModal(false); // Close the modal after submission
    } catch (error) {
      toast.error('Error');
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/subcategory/all`);
      const responses = await axios.get(`${baseUrl}/api/newsubcategory/all`);
      setNewsubcategories(Array.isArray(responses.data) ? responses.data : []);
      setData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      toast.error('Network error: Unable to fetch details');
    }
  };
  useEffect(() => {
    fetchDetails();

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/all`);
        setCategories(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        toast.error('Error fetching categories');
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchSubcategoriesByCategory = async () => {
      if (selectedCategory) {
        try {
          const response = await axios.get(`${baseUrl}/api/subcategory/category/${selectedCategory}`);
          setSubcategories(Array.isArray(response.data) ? response.data : []);
        } catch (error) {
          toast.error('Error fetching subcategories');
        }
      }
    };
    fetchSubcategoriesByCategory();
  }, [selectedCategory]);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/newsubcategory/${id}`);
      toast.success('Newsubcategory Deleted');
      setData(data.filter((item) => item.id !== id));
      fetchDetails();

    } catch (error) {
      toast.error('Error deleting subcategory');
    }
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h1>Add New-Subcategory</h1>
              <button onClick={() => setShowModal(true)}>Add New Subcategory</button>
            </div>
          </div>
        </div>
        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={() => setShowModal(false)}>&times;</span>
              <form onSubmit={handleSubmit}>
                <div>
                  <select
                    id="category"
                    name="category"
                    value={selectedCategory}
                    onChange={(event) => setSelectedCategory(event.target.value)}
                  >
                    <option value="">Select a category</option>
                    {categories?.map((category) => (
                      <option key={category?._id} value={category?._id}>
                        {category?.category}
                      </option>
                    ))}
                  </select>
                  <select
                    id="subcategory"
                    name="subcategory"
                    value={selectedSubCategory}
                    onChange={(event) => setSelectedSubCategory(event.target.value)}
                  >
                    <option value="">Select a subcategory</option>
                    {Array.isArray(subcategories) && subcategories?.map((subcategory) => (
                      <option key={subcategory?._id} value={subcategory?._id}>
                        {subcategory?.subcategory}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    placeholder="Enter newsubcategory"
                    id="newsubcategory"
                    name="newsubcategory"
                    value={newsubcategory}
                    onChange={(event) => setNewsubcategory(event.target.value)}
                  />
                  <input
                    type="file"
                    id="image"
                    onChange={(event) => {
                      handleImageChange(event);
                      setImage(event.target.files[0]);
                    }}
                  />
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Image preview"
                      style={{ width: 100, marginLeft: 10 }}
                    />
                  )}
                  <button type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        )}
        {newsubcategories?.length === 0 ? (
          <p style={{ marginLeft: '150px', color: 'red', fontSize: '18px' }}>
            No data available.
          </p>
        ) : (
          <table style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th>Category</th>
                <th>Subcategory</th>
                <th>Newsubcategory</th>
                <th>Image</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {Array?.isArray(newsubcategories) && newsubcategories?.map((item, index) => (
                <tr key={item.id}>
                  <td>{item?.category?.category}</td>
                  <td>{item?.subcategory?.subcategory}</td>
                  <td>{item?.newsubcategory}</td>
                  <td>
                    <img src={item.image} alt="Denim Jeans" />
                  </td>
                  <td>
                    {/* Add your edit button here */}
                  </td>
                  <td>
                    <button onClick={() => handleDelete(item?._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Newsubcategory;
