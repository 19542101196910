import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './SeatingArrangement.css';
import axios from 'axios';
import Modal from './SeatModal';

const baseUrl = process.env.REACT_APP_BASEURL;

const formatDate = (date) => {
    if (!date) return "";
    const [year, month, day] = date.split("-");
    return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
};

const unformatDate = (date) => {
    if (!date) return "";
    const [month, day, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};

const SeatingArrangement = () => {
    const [data, setData] = useState([]);
    const [selectedSeat, setSelectedSeat] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);

    const fetchDetails = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/seats/all`);
            console.log(response.data);
            setData(response.data);
        } catch (error) {
            console.error("Network error:", error);
        }
    };

    useEffect(() => {
        fetchDetails();
    }, []);

    const handleSeatClick = (seat) => {
        if (seat.isBooked === "true") {
            setSelectedSeat(seat);
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSeat(null);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <div className="seating-arrangement">
            <div className="date-picker">
                <label htmlFor="selectedDate">Select Date:</label>
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="MM/DD/YYYY"
                />
            </div>
            {Array.isArray(data) && data.map(seat => (
                <div
                    key={seat?._id}
                    className={`seat ${seat?.isBooked === "true" ? 'booked' : 'available'}`}
                    onClick={() => handleSeatClick(seat)}
                >
                    <span className="chair-icon">🪑</span>
                    {seat?.seatNumber}
                </div>
            ))}
            <Modal show={showModal} onClose={handleCloseModal} seat={selectedSeat} />
        </div>
    );
};

export default SeatingArrangement;
