import React, { useState } from 'react';

const ProductSelector = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const products = [
    { id: 'p1', name: 'Product 1' },
    { id: 'p2', name: 'Product 2' },
    { id: 'p3', name: 'Product 3' },
    // Add more products as needed
  ];

  const handleProductSelect = (event) => {
    const selectedOptions = Array.from(event.target.selectedOptions);
    const selectedIds = selectedOptions.map(option => option.value);
    setSelectedProducts(selectedIds);
  };

  return (
    <div>
      <h3>Select Products</h3>
      <select multiple onChange={handleProductSelect}>
        {products.map(product => (
          <option key={product.id} value={product.id}>
            {product.name}
          </option>
        ))}
      </select>

      {selectedProducts.length > 0 && (
        <div>
          <h4>Selected Products</h4>
          <ul>
            {selectedProducts.map(productId => (
              <li key={productId}>
                {products.find(product => product.id === productId).name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProductSelector;
