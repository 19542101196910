// FirebaseImageUpload.js

import React, { useState, useEffect } from 'react';
import { storage } from './Config'; // Import Firebase storage instance
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage';

function FirebaseImageUpload() {
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to handle image upload
  const handleImageUpload = async () => {
    if (image) {
      const imageName = `${uuidv4()}_${image.name}`; // Generate a unique name for the image
      const imageRef = ref(storage, `images/${imageName}`);

      try {
        setLoading(true);
        // Upload image to Firebase Storage
        await uploadBytes(imageRef, image);

        // Get download URL for the image
        const url = await getDownloadURL(imageRef);

        // Update imageUrl state with the new image URL
        setImageUrl([...imageUrl, url]);
        setLoading(false);
      } catch (error) {
        console.error('Error uploading image: ', error);
        setLoading(false);
      }
    }
  };

  // Function to handle file input change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  // Function to fetch all images from Firebase Storage
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const imagesRef = ref(storage, 'images');
        const imagesList = await listAll(imagesRef);

        const urls = await Promise.all(imagesList.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return url;
        }));

        setImageUrl(urls);
      } catch (error) {
        console.error('Error fetching images: ', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="App">
      <h1>Upload Images to Firebase Storage</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleImageUpload} disabled={!image || loading}>
        {loading ? 'Uploading...' : 'Upload Image'}
      </button>
      <br />
      <br />
      <div className="image-gallery">
        {imageUrl.map((url, index) => (
          <div key={index}>
            <img src={url} alt={`Image ${index}`} style={{ width: '200px', height: 'auto', marginRight: '10px' }} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FirebaseImageUpload;
