import React from 'react';
import './Modal.css';

const SeatModal = ({ show, onClose, seat }) => {
    if (!show) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Booking Details</h2>
                <p><strong>Seat Number:</strong> {seat?.seatNumber}</p>
                <p><strong>Status:</strong> {seat?.isBooked === "true" ? 'Booked' : 'Available'}</p>
                <p><strong>Type:</strong> {seat?.type}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default SeatModal;
