import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "../Category/Category.css";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    width: '80%',
    maxWidth: '500px',
    fontfamily: 'Fraunces',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: '#f9fde5', 
    border: 'none',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent overlay background
  },
};

const Category = () => {
  const [time, setTime] = useState("");
  const [opentime, setOpentime] = useState("");

  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [editTime, setEditTime] = useState("");
  const [editopenTime, setEditopenTime] = useState("");

  const [editMessage, setEditMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(`${baseUrl}/api/time/`, { time,opentime, message });
      console.log(response.data, 'Category');
      toast.success("Created");
      setLoading(false);
      setTime("");
      setMessage("");
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/time/all`);
      console.log(response.data, "kk");
      setData(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/time/${id}`);
      console.log(response.data, 'Deleted');
      toast.success("Time Deleted");
      setData(data.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setEditTime(category.closingtime);
    setEditopenTime(category.opentime);

    setEditMessage(category.message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setEditCategory({});
    setEditTime("");
    setEditMessage("");
  };

  const handleEditSubmit = async (event) => {
    console.log(editopenTime,'editopenTime');
    event.preventDefault();
    try {
      const response = await axios.put(`${baseUrl}/api/time/edit/${editCategory._id}`, {
        closingtime: editTime,
        opentime:editopenTime,
        message: editMessage
      });
      toast.success(" Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating category");
      setLoading(false);
    }
  };
  const handleToggleClick = async (value, id) => {
    console.log(value, 'value');
    try {
      const response = await axios.put(`${baseUrl}/api/time/onoff/${id}`, {
        "onoff": value
      });
      fetchDetails();

    } catch (error) {
      console.error('Error toggling category', error);
    }
  };
  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="col-sm-12">
                <h1>Add Closing-time and Message</h1>
               
                <input type="text"
                  placeholder="Enter opentime"
                  id="time"
                  name="time"
                  value={opentime}
                  onChange={(event) => setOpentime(event.target.value)}
                /> 
                <input
                type="text"
                  placeholder="Enter closetime"
                  id="time"
                  name="time"
                  value={time}
                  onChange={(event) => setTime(event.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter text message"
                  id="message"
                  name="message"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                />
                <button type="submit" disabled={loading}>
                  {loading ? "Uploading..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        {data?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                  Message
                </th>
                <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                  Open-Time
                </th>
                <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                  Close-Time
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  ON/OFF
                </th>
                <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                  Edit
                </th>
                <th style={{ padding: "10px", backgroundColor: "#f2f2f2", borderBottom: "1px solid #ddd" }}>
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "10px" }}>{item?.message}</td>
                  <td style={{ padding: "10px" }}>{item?.opentime}</td>
                  <td style={{ padding: "10px" }}>{item?.closingtime}</td>

                  {item?.onoff==="ON"?(<>
                  <td style={{ padding: "10px"}}>
                    <button style={{ backgroundColor:'green' }} onClick={() => handleToggleClick('OFF',item?._id)}>ON</button>
                  </td>
                  </>):(<>
                    <td style={{ padding: "10px" }}>
                    <button style={{ backgroundColor:'red' }} onClick={() => handleToggleClick('ON',item?._id)} >OFF</button>
                  </td>
                  </>)}
                  <td style={{ padding: "10px" }}>
                    <button className="edit-button" onClick={() => openModal(item)}>Edit</button>
                  </td>
                  <td style={{ padding: "10px" }}>
                    <button className="delete-button" onClick={() => handleDelete(item._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Category"
        ariaHideApp={false}
      >
        <h2>Edit Time</h2>
        <form onSubmit={handleEditSubmit}>
        <input
            type="number"
            placeholder="Enter time"
            id="editopenTime"
            name="editopenTime"
            value={editopenTime}
            onChange={(event) => setEditopenTime(event.target.value)}
          />
          <input
            type="number"
            placeholder="Enter time"
            id="editTime"
            name="editTime"
            value={editTime}
            onChange={(event) => setEditTime(event.target.value)}
          />
          <input
            type="text"
            placeholder="Enter text message"
            id="editMessage"
            name="editMessage"
            value={editMessage}
            onChange={(event) => setEditMessage(event.target.value)}
          />
          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </button>
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default Category;
