import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";
import '../../index.css'
const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.9)", // Semi-transparent background
    border: "none",
    borderRadius: "10px",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent overlay background
  },
};

const Influencer = () => {
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");
const navigate=useNavigate();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!image) {
      toast.error("Please select an image");
      return;
    }

    try {
      setLoading(true);
      const imageName = `${uuidv4()}_${image.name}`;
      const imageRef = ref(storage, `images/${imageName}`);

      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      const category = document.getElementById("category").value;
      const response = await axios.post(
        `${baseUrl}/api/upload`,
        { category, image: imageUrl },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data, "Category");
      toast.success("Category Added");

      setPreviewImage(null);
      setImage(null);
      setCategory("");
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      setLoadingCategories(true);
      const response = await axios.get(`${baseUrl}/api/products/all/influencer`);
      console.log(response.data, "kk");
      setData(response.data);
      setLoadingCategories(false);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/${id}`);
      console.log(response.data, "Deleted");
      toast.success("Category Deleted");
      setData(data.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setPreviewImage("");
    setImage(null);
    setCategory("");
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setNewPreviewImage(null);
    }
    setNewImage(file);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      let imageUrl = editCategory.image;

      if (newImage) {
        const imageName = `${uuidv4()}_${newImage.name}`;
        const imageRef = ref(storage, `images/${imageName}`);
        await uploadBytes(imageRef, newImage);
        imageUrl = await getDownloadURL(imageRef);
      }

      const response = await axios.put(`${baseUrl}/api/${editCategory._id}`, {
        category: editCategory?.category,
        image: imageUrl,
      });

      console.log(response.data, "Category Updated");
      toast.success("Category Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating category");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/onoff/${id}`, {
        onoff: value,
      });
      console.log(response.data);
      fetchDetails();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };

  return (
    <div style={{display:'flex'}}>

    <div className="categorymaindiv">
      <Navbar />
      <h3>Influencer</h3>
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {loadingCategories ? (
          <p>Loading...</p>
        ) : data?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <div className="table-container">
            <div className="table-wrapper">
              <table style={{ borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={tableHeaderStyle}>Name</th>
                    <th style={tableHeaderStyle}>Email</th>
                    <th style={tableHeaderStyle}>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((item) => (
                    <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                      <td style={{ padding: "10px" }}>{item.name}</td>
                      <td style={{ padding: "10px" }}>{item.email}</td>


                      <td style={{ padding: "10px" }}>
                        <button onClick={() => openModal(item)}>View</button>
                      </td>
                    
                    </tr>
                  ))}
                </tbody>
              </table>
            <button onClick={()=>{navigate('/forms')}} className="fab-button">Bulkorder</button>
<br/>
<button onClick={()=>{navigate('/dealership')}} className="fab-button" style={{ marginTop: '50px' }}>Dealership</button>


            </div>

          </div>
        )}
            
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Edit Category Modal"
        >
          <p>Contact:{editCategory?.contact}</p>
          <p>Socialchannels:{editCategory?.socialchannels}</p>
          <p>Collabrationteams:{editCategory?.collabrationteams}</p>

        </Modal>


     
      </div>
    </div>
    </div>
  );
};

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "10px",
  textAlign: "left",
  fontWeight: "bold",
};

export default Influencer;
