import React, { useEffect, useState } from 'react';
import './navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  const handlenavigate = () => {
    localStorage.removeItem("Admin");
    localStorage.removeItem("authtoken");
    navigate("/");
  };

  const handleLinkClick = (path) => {
    navigate(path);
  };

  const links = [
    { path: "/category", label: "Category" },
    { path: "/subcategory", label: "Subcategory" },
    { path: "/products", label: "Products" },
    { path: "/blog", label: "Blog" },
    { path: "/gallery", label: "Gallery" },

    { path: "/contactus", label: "Contact Us" },
    { path: "/aboutus", label: "About Us" },
    { path: "/variation", label: "Variation" },
    { path: "/bulkorder", label: "Bulkorder" },
    { path: "/orders", label: "Orders" },
    { path: "/forms", label: "Forms" },
    { path: "/homepage", label: "Home page" },
    { path: "/career", label: "Career" },
    { path: "/terms", label: "Terms" },
    { path: "/carousal", label: "Carousal" },
    { path: "/policy", label: "Policy" },
    { path: "/application", label: "Career-Applications" },
    { path: "/home", label: "Our Story" },
    { path: "/link", label: "Link" },
    { path: "/social", label: "Social Media" },
    { path: "/training", label: "Training" },
    { path: "/time", label: "Close Time" },
    { path: "/coupon", label: "Coupon" },

  ];

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    <div className="sidenav">
      {links.map((link) => (
        <a
          key={link.path}
          onClick={() => handleLinkClick(link.path)}
          className={activeLink === link.path ? "active-link" : ""}
        >
          {link.label}
        </a>
      ))}
      <a onClick={handlenavigate} className="logout-btn">
        Logout
      </a>
      <div className="freebox"></div>
    </div>
  );
};

export default Navbar;
