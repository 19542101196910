import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Navbar from "../../Components/Navbar";
const baseUrl = process.env.REACT_APP_BASEURL;

const Socialmedia = () => {
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [newPreviewImage, setNewPreviewImage] = useState("");
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [ADDON, setADDON] = useState("");
  const [funtoppinsheading, setfuntoppinsheading] = useState("");
  const [minQuantity, setMinQuantity] = useState("");

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [facebook, setfacebook] = useState("");
  const [linkedlin, setlinkedlin] = useState("");

  const [productPrice, setProductPrice] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [variations, setVariations] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productsdata, setproductsdata] = useState([]);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [orderType, setOrderType] = useState("");
  const [bulkorder, setbulkorder] = useState("");
  const [newImage, setNewImage] = useState(null);

  console.log(bulkorder, minQuantity, "hhh");

  const [isAddon, setIsAddon] = useState(null);
  const [funtoppins, setfuntoppins] = useState(null);
  const [funtoppinsList, setFuntoppinsList] = useState([
    { name: "", price: "" },
  ]);
  const [error, setError] = useState("");
  console.log(funtoppinsList, "funtoppinsList");

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const list = [...funtoppinsList];
    list[index][name] = value;
    setFuntoppinsList(list);
  };

  const handleAddClick = () => {
    setFuntoppinsList([...funtoppinsList, { name: "", price: "" }]);
  };
  const isPriceValid = (price) => price > 5;

  const handleRadioChange = (event) => {
    setIsAddon(event.target.value === "true");
  };

  const handlefuntoppinsRadioChange = (event) => {
    setfuntoppins(event.target.value === "true");
  };
  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };
  const handlebulkorderChange = (e) => {
    setbulkorder(e.target.value);
    // Reset minQuantity when bulkorder is changed to "No"
    if (e.target.value === "No") {
      setMinQuantity("");
    }
  };
  console.log(orderType, "orderType");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    setImage(file);
  };
  const openEditModal = (product) => {
    setProductToEdit(product);
    setEditModalIsOpen(true);
  };

  const closeEditModal = () => {
    setProductToEdit(null);
    setEditModalIsOpen(false);
  };
  const handleMinQuantityChange = (e) => {
    setMinQuantity(e.target.value);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
    
      

      const updatedProductData = {
      
        linkedlin: productToEdit?.linkedlin,
        facebook: productToEdit?.facebook,
        instagram: productToEdit?.instagram,

     
     
      };

      const response = await axios.put(
        `${baseUrl}/api/socialmedia/edit/${productToEdit._id}`,
        updatedProductData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Product Updated");
      resetForm();
      setEditModalIsOpen(false);
      fetchproducts();
    } catch (error) {
      console.error(error);
      toast.error("Error updating product");
    }
  };

  const handleCategoryChange = async (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    const response = await axios.get(
      `${baseUrl}/api/subcategory/category/${selectedValue}`
    );
    setSubCategories(response?.data);
    const responses = await axios.post(`${baseUrl}/api/variation/category`, {
      category: selectedValue,
    });
    setVariations(responses?.data);
    console.log(responses?.data, "tt");
    console.log(response?.data, "dataa");
    performAdditionalLogic(response?.data?.category);
  };

  const performAdditionalLogic = (selectedValue) => {
    // Additional logic can be added here
    console.log(`Category selected: ${selectedValue}`);
    // For example, reset the selected subcategory
    setSelectedSubCategory("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
   
    try {
 

      const productData = {
        instagram: productDescription,
        linkedlin: linkedlin,
        facebook: facebook,


      };

      const response = await axios.post(
        `${baseUrl}/api/socialmedia`,
        productData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchproducts();
      toast.success("Created");
      resetForm();
      setModalIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Error creating product");
    }
  };

  const resetForm = () => {
    setProductName("");
    setProductDescription("");
    setProductPrice("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setImages([]);
    setPreviewImages([]);
    setVariations([]);
  };
  const fetchproducts = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/socialmedia/all`);
      console.log(response.data, "kk");
      setproductsdata(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/all`);
        const response1 = await axios.get(`${baseUrl}/api/subcategory/all`);
        setCategories(Array.isArray(response.data) ? response.data : []);
        setSubCategories(Array.isArray(response1.data) ? response1.data : []);
        console.log(response?.data, "categories");
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Error fetching categories");
      }
    };
    fetchSubCategories();
    fetchproducts();
  }, []);

  const handleSelectionChange = (itemId) => {
    console.log(itemId, "itemId");
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handlePriceChange = (itemId, newPrice) => {
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId ? { ...item, price: newPrice } : item
      )
    );
  };

  const handleCloseModal = () => {
    setPreviewImages([]); // Clear preview images when closing modal
    setModalIsOpen(false); // Close the modal
  };
  const handleViewClick = (product) => {
    setSelectedProduct(product);
    setViewModalIsOpen(true); // Open view modal
  };
  const handleToggleClick = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/socialmedia/onoff/${id}`, {
        onoff: value,
      });
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleToggleClickstock = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/products/stock/${id}`, {
        stock: value,
      });
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleToggleClickbulkorder = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(
        `${baseUrl}/api/products/bulkorder/${id}`,
        {
          bulkorder: value,
        }
      );
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/socialmedia/onoff/${id}`);
      console.log(response.data, "Deleted");
      toast.success("Link Deleted");
      setproductsdata(productsdata.filter((item) => item._id !== id));
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };
  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };
  const handleAttributeSelectionChange = (itemId, attributeId) => {
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId
          ? {
              ...item,
              attributes: item.attributes.map((attribute) =>
                attribute._id === attributeId
                  ? { ...attribute, selected: !attribute.selected }
                  : attribute
              ),
            }
          : item
      )
    );
  };
  const handleAttributePriceChange = (itemId, attributeId, newPrice) => {
    console.log(itemId, attributeId, newPrice, "hhh");
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId
          ? {
              ...item,
              attributes: item.attributes.map((attribute) =>
                attribute._id === attributeId
                  ? { ...attribute, price: newPrice }
                  : attribute
              ),
            }
          : item
      )
    );
  };
  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setNewPreviewImage(null);
    }
    setNewImage(file);
  };
  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="Homepagedata-container">

      <div style={styles.container}>
        <h1 style={styles.heading}>Socialmedia datas</h1>

        {productsdata?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <div className="scrollable-box">
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Fb
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Instagram
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Linkedlin
                  </th>
                 
                 
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    ON/OFF
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Edit
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Delete
                  </th>
                
               
                </tr>
              </thead>
              <tbody>
                {productsdata?.map((item) => (
                  <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                   
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                     {item?.facebook}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                     {item?.instagram}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                     {item?.linkedlin}
                    </td>
                    {item?.onoff === "ON" ? (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() => handleToggleClick("OFF", item?._id)}
                        >
                          ON
                        </button>
                      </td>
                    ) : (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() => handleToggleClick("ON", item?._id)}
                        >
                          OFF
                        </button>
                      </td>
                    )}
                    <td style={{ padding: "10px" }}>
                      <button className="edit-button" onClick={() => openEditModal(item)}>Edit</button>
                    </td>

                    <td style={{ padding: "10px" }}>
                      <button className="delete-button" onClick={() => handleDelete(item?._id)}>
                        Delete
                      </button>
                    </td>
              
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button
          onClick={() => setModalIsOpen(true)}
          style={styles.addButton}
          className="fab-button"
        >
          Add Link
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Add Product"
          style={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={styles.heading}>Create</h2>
            <button
              onClick={handleCloseModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "5px",
              }}
            >
              ×
            </button>
          </div>
          <form onSubmit={handleSubmit} style={styles.form}>
           
  
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
                 Instagram
              </label>
              <input
                id="productDescription"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
                Linkedlin
              </label>
              <input
                id="linkedlin"
                value={linkedlin}
                onChange={(e) => setlinkedlin(e.target.value)}
                required
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
                facebook
              </label>
              <input
                id="facebook"
                value={facebook}
                onChange={(e) => setfacebook(e.target.value)}
                required
                style={styles.textarea}
              />
            </div>
            {/* <div style={styles.formGroup}>
              <label htmlFor="productPrice" style={styles.label}>
                Link
              </label>
              <input
             
                id="productPrice"
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)}
                required
                style={styles.input}
              />
            </div> */}
          
            <div style={styles.buttonContainer}>
              <button type="submit" style={styles.button}>
                Create 
              </button>
              <button
                type="button"
                onClick={resetForm}
                style={styles.resetButton}
              >
                Reset Form
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Product"
          style={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={styles.heading}>Edit Product</h2>
            <button
              onClick={closeEditModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "5px",
              }}
            >
              ×
            </button>
          </div>
          <form onSubmit={handleEditSubmit} style={styles.form}>

            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
               Facebook
              </label>
              <textarea
                id="productDescription"
                value={productToEdit?.facebook}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    facebook: event.target.value,
                  })
                }
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
               Instagram
              </label>
              <textarea
                id="productDescription"
                value={productToEdit?.instagram}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    instagram: event.target.value,
                  })
                }
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
               Linkedlin
              </label>
              <textarea
                id="linkedlin"
                value={productToEdit?.linkedlin}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    linkedlin: event.target.value,
                  })
                }
                style={styles.textarea}
              />
            </div>
        
            <div style={styles.formGroup}>
              <label htmlFor="productImages" style={styles.label}>
                Product Image
              </label>
             
            </div>
        
        
            <div style={styles.buttonContainer}>
              <button type="submit" style={styles.button}>
                Update Product
              </button>
              <button
                type="button"
                onClick={resetForm}
                style={styles.resetButton}
              >
                Reset Form
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={viewModalIsOpen}
          onRequestClose={() => setViewModalIsOpen(false)}
          style={customStyles}
          contentLabel="View Product Details"
          ariaHideApp={false}
        >
          <h2> Details</h2>
          {selectedProduct && (
            <div>
             
             
              {selectedProduct?.image?.map((item, index) => (
                <img src={item} style={{height:"50px"}}/>
              ))}
             <p>Description:{selectedProduct?.description}</p>
           

            </div>
          )}
          <button onClick={() => setViewModalIsOpen(false)}>Close</button>
        </Modal>
        <ToastContainer />
      </div>
      </div>

    </div>
  );
};

const styles = {
  // container: {
  //   maxWidth: "80%",
  //   margin: "0 auto",
  //   padding: "20px",
  //   backgroundColor: "#f9f9f9",
  //   borderRadius: "8px",
  //   boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  //   minHeight: "100vh",
  //   position: "relative",
  // },
  heading: {
    textAlign: "center",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "10px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#555",
  },
  input: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
  },
  textarea: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    minHeight: "40px",
    width: "94%",
  },
  select: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "94%",
  },
  fileInput: {
    padding: "8px",
    width: "94%",
  },
  imagePreviewContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: "20px", // Increased bottom margin to create space between images and buttons
  },
  imagePreview: {
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "8px 16px",
    backgroundColor: "#ceee36",
    color: "#000",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "10px",
  },
  resetButton: {
    padding: "8px 16px",
    backgroundColor: "#DC3545",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "8px 16px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  checkbox: {
    marginRight: "10px",
  },
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    maxWidth: "500px",
    fontfamily: "Fraunces",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#f9fde5",
    border: "none",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent overlay background
  },
};
const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    padding: "15px",
    borderRadius: "8px",
    overflowY: "auto",
    maxHeight: "80vh",
  },
};

export default Socialmedia;

