import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Category from "./Pages/Category/Category";
import Subcategory from "./Pages/Subcategory/Subcategory";
import Newsubcategory from "./Pages/Newsubcategory/Newsubcategory";
import Products from "./Pages/Products/Products";
import FirebaseImageUpload from "./Pages/Firebase/Firebaseuploader";
import Blog from "./Pages/Blog/Blog";
import Login from "./Pages/Login/Login";
import Variation from "./Pages/variation/Variation";
import SeatingArrangement from "./Pages/Seats/SeatingArrangement";
import Time from "./Pages/Time/Time";
import Aboutus from "./Pages/About/Aboutus";
import Funtoppins from "./Pages/Funtoppins/Funtoppins";
import FuntoppinsHeading from "./Pages/Funtoppins/FuntoppinsHeading";
import Variationheading from "./Pages/variation/Variationheading";
import AdminRegistrationForm from "./Pages/Login/AdminRegistrationForm";
import Contactus from "./Pages/Contact/Contact";
import Order from "./Pages/orders/Order";
import Form from "./Pages/Form/Form";
import Influencer from "./Pages/Form/Influencer";
import Dealership from "./Pages/Form/Dealership";
import PrivateRoute from "./Components/PrivateRoute"; 
import ProductSelector from "./Pages/ProductSelector";
import Career from "./Pages/Career/Career";
import Terms from "./Pages/Terms/Terms";
import Carousal from "./Pages/Carousal/Carousal";
import Policy from "./Pages/Policy/Policy";
import Training from "./Pages/Training/Training";
import Application from "./Pages/Applications/Application";
import Homepage from "./Pages/Products/Homepage";
import Bulkorder from "./Pages/Bulkorder/Bulkorder";
import LinkPage from "./Pages/Products/LinkPage";
import Socialmedia from "./Pages/Socialmedia/Socialmedia";
import Homemain from "./Pages/Homemain/Homemain";
import Coupon from "./Pages/Coupon/Coupon";
import Gallary from "./Pages/Blog/Gallary";

function App() {
  
  return (
    <React.Fragment>
      <Router>
        <Routes>
          <Route path="/category" element={<PrivateRoute element={Category} />} />
          <Route path="/subcategory" element={<PrivateRoute element={Subcategory} />} />
          <Route path="/newsubcategory" element={<PrivateRoute element={Newsubcategory} />} />
          <Route path="/variation" element={<PrivateRoute element={Variation} />} />
          <Route path="/variation-heading" element={<PrivateRoute element={Variationheading} />} />
          <Route path="/contactus" element={<PrivateRoute element={Contactus} />} />
          <Route path="/orders" element={<PrivateRoute element={Order} />} />
          <Route path="/forms" element={<PrivateRoute element={Form} />} />
          <Route path="/funtoppings" element={<PrivateRoute element={Funtoppins} />} />
          <Route path="/funtoppings-heading" element={<PrivateRoute element={FuntoppinsHeading} />} />
          <Route path="/signup" element={<AdminRegistrationForm />} />
          <Route path="/influencer" element={<PrivateRoute element={Influencer} />} />
          <Route path="/dealership" element={<PrivateRoute element={Dealership} />} />
          <Route path="/products" element={<PrivateRoute element={Products} />} />
          <Route path="/firebase" element={<PrivateRoute element={FirebaseImageUpload} />} />
          <Route path="/blog" element={<PrivateRoute element={Blog} />} />
          <Route path="/gallery" element={<PrivateRoute element={Gallary} />} />

          <Route path="/" element={<Login />} />
          <Route path="/seats" element={<PrivateRoute element={SeatingArrangement} />} />
          <Route path="/time" element={<PrivateRoute element={Time} />} />
          <Route path="/aboutus" element={<PrivateRoute element={Aboutus} />} />
          <Route path="/all" element={<ProductSelector />} />
          <Route path="/career" element={<Career />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/Carousal" element={<Carousal />} />
          <Route path="/Policy" element={<Policy />} />
          <Route path="/training" element={<Training />} />
          <Route path="/application" element={<Application />} />
          <Route path="/home" element={<Homepage />} />
          <Route path="/link" element={<LinkPage />} />
          <Route path="/social" element={<Socialmedia />} />
          <Route path="/homepage" element={<Homemain />} />
          <Route path="/coupon" element={<Coupon />} />

          <Route path="/bulkorder" element={<Bulkorder />} />

        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
