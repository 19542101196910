import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Modal from "react-modal";
import Navbar from "../../Components/Navbar";
import "./products.css";
const baseUrl = process.env.REACT_APP_BASEURL;

const Products = () => {
  const [loading, setLoading] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false);
  const [newPreviewImage, setNewPreviewImage] = useState("");
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [ADDON, setADDON] = useState("");
  const [funtoppinsheading, setfuntoppinsheading] = useState("");
  const [minQuantity, setMinQuantity] = useState("");

  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [discount, setDiscount] = useState("");
  const [offerdescription, setofferdescription] = useState("");
  const [validEnd, setValidEnd] = useState("");
  const [validStart,setValidStart ] = useState("");

  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [variations, setVariations] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [offermodalIsOpen, setofferModalIsOpen] = useState(false);

  const [productsdata, setproductsdata] = useState([]);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [orderType, setOrderType] = useState("");
  const [bulkorder, setbulkorder] = useState("");
  console.log(bulkorder, minQuantity, "hhh");

  const [isAddon, setIsAddon] = useState(null);
  const [funtoppins, setfuntoppins] = useState(null);
  const [funtoppinsList, setFuntoppinsList] = useState([
    { name: "", price: "" },
  ]);
  const [error, setError] = useState("");
  console.log(funtoppinsList, "funtoppinsList");

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const list = [...funtoppinsList];
    list[index][name] = value;
    setFuntoppinsList(list);
  };

  const handleAddClick = () => {
    setFuntoppinsList([...funtoppinsList, { name: "", price: "" }]);
  };
  const isPriceValid = (price) => price > 5;

  const handleRadioChange = (event) => {
    setIsAddon(event.target.value === "true");
  };

  const handlefuntoppinsRadioChange = (event) => {
    setfuntoppins(event.target.value === "true");
  };
  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
  };
  const handlebulkorderChange = (e) => {
    setbulkorder(e.target.value);
    if (e.target.value === "No") {
      setMinQuantity("");
    }
  };
  console.log(orderType, "orderType");

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prevPreviews) => [...prevPreviews, ...previews]);
  };
  const openEditModal = (product) => {
    setProductToEdit(product);
    setEditModalIsOpen(true);
  };
  const openofferModal = (product) => {
    setProductToEdit(product);
    setofferModalIsOpen(true)  };
  const closeEditModal = () => {
    setProductToEdit(null);
    setEditModalIsOpen(false);
  };
  const closeofferModal = () => {
    setProductToEdit(null);
    setofferModalIsOpen(false);
  };
  const handleMinQuantityChange = (e) => {
    setMinQuantity(e.target.value);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      const updatedImages = await Promise.all(
        images.map(async (image) => {
          if (image instanceof File) {
            const imageName = `${uuidv4()}_${image.name}`;
            const imageRef = ref(storage, `images/${imageName}`);
            await uploadBytes(imageRef, image);
            const imageUrl = await getDownloadURL(imageRef);
            return imageUrl;
          }
          return image;
        })
      );

      const selectedVariations = variations
        .filter((variation) => variation.selected)
        .map((variation) => ({
          variationname: variation.variationname,
          price: variation.price,
          attributes: variation.attributes
            .filter((attribute) => attribute.selected)
            .map((attribute) => ({
              name: attribute.name,
              price: attribute.price,
            })),
        }));
        if(funtoppins===false){

      const updatedProductData = {
        productname: productToEdit?.productname,
        description: productToEdit?.description,
        price: productToEdit?.price,
        category: selectedCategory,
        subcategory: selectedSubCategory,
        image: updatedImages,
        bulkorder: bulkorder,
        minquantity: minQuantity,
        variations: selectedVariations,
        options: orderType,
        variationheading: ADDON,
        funtoppinsList: [],
        funtoppinsheading: funtoppinsheading,
      };

      const response = await axios.put(
        `${baseUrl}/api/products/${productToEdit._id}`,
        updatedProductData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }else{
      const updatedProductData = {
        productname: productToEdit?.productname,
        description: productToEdit?.description,
        price: productToEdit?.price,
        category: selectedCategory,
        subcategory: selectedSubCategory,
        image: updatedImages,
        bulkorder: bulkorder,
        minquantity: minQuantity,
        variations: selectedVariations,
        options: orderType,
        variationheading: ADDON,
        funtoppinsList: funtoppinsList,
        funtoppinsheading: funtoppinsheading,
      };

      const response = await axios.put(
        `${baseUrl}/api/products/${productToEdit._id}`,
        updatedProductData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    }

      toast.success("Product Updated");
      resetForm();
      setEditModalIsOpen(false);
      fetchproducts();
    } catch (error) {
      console.error(error);
      toast.error("Error updating product");
    }
  };
  const handleofferSubmit = async (event) => {
    event.preventDefault();
    try {
   
  
      const updatedProductData = {
        discount: discount,
        description: offerdescription,
        startdate:validStart,
        endtdate:validEnd
      
      };

      const response = await axios.put(
        `${baseUrl}/api/products/offer/${productToEdit._id}`,
        updatedProductData,
       
      );

      toast.success("Product Updated");
      resetForm();
      setEditModalIsOpen(false);
      fetchproducts();
    } catch (error) {
      console.error(error);
      toast.error("Error updating product");
    }
  };
  const handleCategoryChange = async (e) => {
    const selectedValue = e.target.value;
    setSelectedCategory(selectedValue);
    const response = await axios.get(
      `${baseUrl}/api/subcategory/category/${selectedValue}`
    );
    setSubCategories(response?.data);
    const responses = await axios.post(`${baseUrl}/api/variation/category`, {
      category: selectedValue,
    });
    setVariations(responses?.data);
    console.log(responses?.data, "tt");
    console.log(response?.data, "dataa");
    performAdditionalLogic(response?.data?.category);
  };

  const performAdditionalLogic = (selectedValue) => {
    // Additional logic can be added here
    console.log(`Category selected: ${selectedValue}`);
    // For example, reset the selected subcategory
    setSelectedSubCategory("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const imageName = `${uuidv4()}_${image.name}`;
          const imageRef = ref(storage, `images/${imageName}`);
          await uploadBytes(imageRef, image);
          const imageUrl = await getDownloadURL(imageRef);
          return imageUrl;
        })
      );

      const selectedVariations = variations
        .filter((variation) => variation.selected)
        .map((variation) => ({
          variationname: variation.variationname,
          price: variation.price,
          attributes: variation.attributes
            .filter((attribute) => attribute.selected)
            .map((attribute) => ({
              name: attribute.name,
              price: attribute.price,
            })),
        }));
if(funtoppins===false){
  const productData = {
    productname: productName,
    description: productDescription,
    price: productPrice,
    category: selectedCategory,
    subcategory: selectedSubCategory,
    images: imageUrls,
    variations: selectedVariations,
    options: orderType,
    variationheading: ADDON,
    funtoppinsList: [],
    funtoppinsheading: funtoppinsheading,
  };

  const response = await axios.post(
    `${baseUrl}/api/products/`,
    productData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}else{
  
  const productData = {
    productname: productName,
    description: productDescription,
    price: productPrice,
    category: selectedCategory,
    subcategory: selectedSubCategory,
    images: imageUrls,
    variations: selectedVariations,
    options: orderType,
    variationheading: ADDON,
    funtoppinsList: funtoppinsList,
    funtoppinsheading: funtoppinsheading,
  };

  const response = await axios.post(
    `${baseUrl}/api/products/`,
    productData,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
      fetchproducts();
      toast.success("Product Created");
      setLoading(false);
      resetForm();
      setModalIsOpen(false);
    } catch (error) {
      console.error(error);
      toast.error("Error creating product");
    }
  };

  const resetForm = () => {
    setProductName("");
    setProductDescription("");
    setProductPrice("");
    setSelectedCategory("");
    setSelectedSubCategory("");
    setImages([]);
    setPreviewImages([]);
    setVariations([]);
  };
  const fetchproducts = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/products/all`);
      console.log(response.data, "kk");
      setproductsdata(response.data);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
    }
  };
  useEffect(() => {
    const fetchSubCategories = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/all`);
        const response1 = await axios.get(`${baseUrl}/api/subcategory/all`);
        setCategories(Array.isArray(response.data) ? response.data : []);
        setSubCategories(Array.isArray(response1.data) ? response1.data : []);
        console.log(response?.data, "categories");
      } catch (error) {
        console.error("Error fetching categories:", error);
        toast.error("Error fetching categories");
      }
    };
    fetchSubCategories();
    fetchproducts();
  }, []);

  const handleSelectionChange = (itemId) => {
    console.log(itemId, "itemId");
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handlePriceChange = (itemId, newPrice) => {
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId ? { ...item, price: newPrice } : item
      )
    );
  };

  const handleCloseModal = () => {
    setPreviewImages([]); // Clear preview images when closing modal
    setModalIsOpen(false); // Close the modal
  };
  const handleViewClick = (product) => {
    setSelectedProduct(product);
    setViewModalIsOpen(true); // Open view modal
    
  };
  const handleToggleClick = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/products/onoff/${id}`, {
        onoff: value,
      });
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleToggleClickstock = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(`${baseUrl}/api/products/stock/${id}`, {
        stock: value,
      });
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleToggleClickbulkorder = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(
        `${baseUrl}/api/products/bulkorder/${id}`,
        {
          bulkorder: value,
        }
      );
      console.log(response.data);
      fetchproducts();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/products/${id}`);
      console.log(response.data, "Deleted");
      toast.success("Product Deleted");
      setproductsdata(productsdata.filter((item) => item._id !== id));
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };
  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };
  const handleAttributeSelectionChange = (itemId, attributeId) => {
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId
          ? {
              ...item,
              attributes: item.attributes.map((attribute) =>
                attribute._id === attributeId
                  ? { ...attribute, selected: !attribute.selected }
                  : attribute
              ),
            }
          : item
      )
    );
  };
  const handleAttributePriceChange = (itemId, attributeId, newPrice) => {
    console.log(itemId, attributeId, newPrice, "hhh");
    setVariations((prevVariations) =>
      prevVariations.map((item) =>
        item._id === itemId
          ? {
              ...item,
              attributes: item.attributes.map((attribute) =>
                attribute._id === attributeId
                  ? { ...attribute, price: newPrice }
                  : attribute
              ),
            }
          : item
      )
    );
  };
  const handleImageUpload = async (index, event) => {
    const file = event.target.files[0];

    if (file) {
      const imageName = `${uuidv4()}_${file.name}`; // Unique image name
      const imageRef = ref(storage, `images/${imageName}`); // Reference to Firebase storage

      try {
        // Upload the image to Firebase storage
        await uploadBytes(imageRef, file);

        // Get the download URL after upload
        const imageUrl = await getDownloadURL(imageRef);

        // Update the topping list with the image URL
        const updatedToppings = [...funtoppinsList];
        updatedToppings[index].image = imageUrl; // Store image URL in state
        setFuntoppinsList(updatedToppings);

        console.log("Image uploaded successfully:", imageUrl);
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div style={styles.container}>
        <h1 style={styles.heading}>Products</h1>

        {productsdata?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <div className="scrollable-box">
            <table style={{ borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Category-Name
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Productname
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                      cursor: "pointer",
                    }}
                    onClick={(item) => handleViewClick(item)}
                  >
                    <FontAwesomeIcon icon={faEye} /> View
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    ON/OFF
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Edit
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Delete
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Stock
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Bulk-order
                  </th>
                  <th
                    style={{
                      padding: "10px",
                      backgroundColor: "#f2f2f2",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                   Offer
                  </th>
                </tr>
              </thead>
              <tbody>
                {productsdata?.map((item) => (
                  <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                    <td style={{ padding: "10px" }}>
                      {item?.category?.category}
                    </td>
                    <td style={{ padding: "10px" }}>{item?.productname}</td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "center",
                        borderRight: "1px solid #ddd",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEye}
                        onClick={() => handleViewClick(item)}
                        style={{ cursor: "pointer", color: "#007bff" }}
                      />
                    </td>
                    {item?.onoff === "ON" ? (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() => handleToggleClick("OFF", item?._id)}
                        >
                          ON
                        </button>
                      </td>
                    ) : (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() => handleToggleClick("ON", item?._id)}
                        >
                          OFF
                        </button>
                      </td>
                    )}
                    <td style={{ padding: "10px" }}>
                      <button
                        className="edit-button"
                        onClick={() => openEditModal(item)}
                      >
                        Edit
                      </button>
                    </td>

                    <td style={{ padding: "10px" }}>
                      <button
                        className="delete-button"
                        onClick={() => handleDelete(item?._id)}
                      >
                        Delete
                      </button>
                    </td>
                    {item?.stock === "True" ? (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() =>
                            handleToggleClickstock("False", item?._id)
                          }
                        >
                          In stock
                        </button>
                      </td>
                    ) : (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() =>
                            handleToggleClickstock("True", item?._id)
                          }
                        >
                          Out of stock
                        </button>
                      </td>
                    )}
                    {item?.bulkorder === "True" ? (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() =>
                            handleToggleClickbulkorder("False", item?._id)
                          }
                        >
                          True
                        </button>
                      </td>
                    ) : (
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() =>
                            handleToggleClickbulkorder("True", item?._id)
                          }
                        >
                          False
                        </button>
                      </td>
                    )}
                     <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() => openofferModal(item)}

                        >
                          Offer
                        </button>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <button
          onClick={() => setModalIsOpen(true)}
          style={styles.addButton}
          className="fab-button"
        >
          Add Product
        </button>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Add Product"
          style={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={styles.heading}>Create Product</h2>
            <button
              onClick={handleCloseModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "5px",
              }}
            >
              ×
            </button>
          </div>
          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={styles.formGroup}>
              <label htmlFor="productName" style={styles.label}>
                Product Name
              </label>
              <input
                type="text"
                id="productName"
                value={productName}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[a-zA-Z\s]*$/.test(value)) {
                    setProductName(value);
                  }
                }}
                // onChange={(e) => setProductName(e.target.value)}
                required
                style={styles.input}
              />
            </div>
            <label htmlFor="orderType">Order Type:</label>
            <select
              id="orderType"
              value={orderType}
              onChange={handleOrderTypeChange}
              required
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Dinein">Dine In</option>
              <option value="Takeaway">Take Away</option>
              <option value="Both">Both</option>
            </select>
            <label htmlFor="orderType">Bulkorder:</label>

            <select
              id="bulkorder"
              value={bulkorder}
              onChange={handlebulkorderChange}
              required
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
            {bulkorder === "Yes" && (
              <div>
                <label htmlFor="minQuantity">Minimum Quantity:</label>
                <input
                  type="number"
                  id="minQuantity"
                  value={minQuantity}
                  onChange={handleMinQuantityChange}
                  min="1"
                  required
                />
              </div>
            )}
            <div style={styles.formGroup}>
              <label htmlFor="category" style={styles.label}>
                Category
              </label>
              <select
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                style={styles.select}
                required
              >
                <option value="" disabled>
                  Select a category
                </option>
                {categories?.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category?.category}
                  </option>
                ))}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="subcategory" style={styles.label}>
                Subcategory
              </label>
              <select
                id="subcategory"
                name="subcategory"
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                style={styles.select}
                required
              >
                <option value="" disabled>
                  Select a subcategory
                </option>
                {Array?.isArray(subCategories) &&
                  subCategories?.map((subCategory) => (
                    <option key={subCategory?._id} value={subCategory?._id}>
                      {subCategory?.subcategory}
                    </option>
                  ))}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
                Product Description
              </label>
              <textarea
                id="productDescription"
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)}
                required
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productPrice" style={styles.label}>
                Product Price
              </label>
              <input
                type="number"
                id="productPrice"
                value={productPrice}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setProductPrice(value);
                  }
                }}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.formGroup}>
              <label htmlFor="productImages" style={styles.label}>
                Product Images
              </label>
              <input
                type="file"
                id="productImages"
                onChange={handleImageChange}
                multiple
                style={styles.fileInput}
              />
            </div>
            <div style={styles.imagePreviewContainer}>
              {previewImages?.map((src, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <img
                    src={src}
                    alt={`Preview ${index}`}
                    style={styles.imagePreview}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const updatedPreviews = [...previewImages];
                      updatedPreviews.splice(index, 1);
                      setPreviewImages(updatedPreviews);
                    }}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "none",
                      border: "none",
                      color: "#fff",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div>
              <h3>Select Addon Status</h3>
              <label>
                <input
                  type="radio"
                  name="addon"
                  value="true"
                  checked={isAddon === true}
                  onChange={handleRadioChange}
                />
                Yes (Addon is True)
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="addon"
                  value="false"
                  checked={isAddon === false}
                  onChange={handleRadioChange}
                />
                No (Addon is False)
              </label>
            </div>
            {isAddon === true ? (
              <>
                <label htmlFor="productName" style={styles.label}>
                  Enter Addon HEADING
                </label>
                <input
                  type="text"
                  id="setADDON"
                  value={ADDON}
                  onChange={(e) => setADDON(e.target.value)}
                  required
                  style={styles.input}
                />
                {variations?.map((item) => (
                  <div key={item._id}>
                    <input
                      type="checkbox"
                      id={`item-${item._id}`}
                      checked={item.selected}
                      onChange={() => handleSelectionChange(item._id)}
                      style={styles.checkbox}
                    />
                    <label htmlFor={`item-${item._id}`} style={styles.label}>
                      {item?.variationname}
                    </label>
                    {item?.selected && (
                      <>
                        <input
                          type="text"
                          placeholder="Enter price"
                          value={item?.price}
                          onChange={(e) =>
                            handlePriceChange(item._id, e.target.value)
                          }
                          style={styles.input}
                        />
                        {item?.attributes?.map((attribute) => (
                          <div
                            key={attribute._id}
                            style={styles.attributeContainer}
                          >
                            <input
                              type="checkbox"
                              id={`attribute-${attribute._id}`}
                              checked={attribute.selected}
                              onChange={() =>
                                handleAttributeSelectionChange(
                                  item._id,
                                  attribute._id
                                )
                              }
                              style={styles.checkbox}
                            />
                            <label
                              htmlFor={`attribute-${attribute._id}`}
                              style={styles.label}
                            >
                              {attribute?.name}
                            </label>
                            {attribute?.selected && (
                              <input
                                type="text"
                                placeholder="Enter price"
                                value={attribute?.price}
                                onChange={(e) =>
                                  handleAttributePriceChange(
                                    item._id,
                                    attribute._id,
                                    e.target.value
                                  )
                                }
                                style={styles.input}
                              />
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
            <div>
              <h3>Select Funtoppins Status</h3>
              <label>
                <input
                  type="radio"
                  name="funtoppins"
                  value="true"
                  checked={funtoppins === true}
                  onChange={handlefuntoppinsRadioChange}
                />
                Yes (Addon is True)
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="funtoppins"
                  value="false"
                  checked={funtoppins === false}
                  onChange={handlefuntoppinsRadioChange}
                />
                No (Addon is False)
              </label>
            </div>
            <>
              {funtoppins === true ? (
                <>
                  <label htmlFor="productName" style={styles.label}>
                    Enter Funtoppins HEADING
                  </label>
                  <input
                    type="text"
                    id="funtoppinsheading"
                    value={funtoppinsheading}
                    onChange={(e) => setfuntoppinsheading(e.target.value)}
                    required
                    style={styles.input}
                  />
                  {funtoppinsList.map((topping, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <label>
                        Topping Name:
                        <input
                          type="text"
                          name="name"
                          value={topping.name}
                          onChange={(e) => handleInputChange(index, e)}
                          placeholder="Enter topping name"
                        />
                      </label>
                      <div className="product-Topping-Price">
                        <label style={{ marginLeft: "10px" }}>
                          Topping Price:
                          <input
                            type="number"
                            name="price"
                            value={topping.price}
                            onChange={(e) => handleInputChange(index, e)}
                            placeholder="Enter topping price"
                          />
                        </label>
                      </div>
                      <div
                        className="product-Topping-Image"
                        style={{ marginTop: "10px" }}
                      >
                        <label>
                          Topping Image:
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(index, e)}
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className="products-Addmore" onClick={handleAddClick}>
                    Add More Topping
                  </div>
                </>
              ) : (
                <>
                  <p>No toppings available.</p>
                </>
              )}
            </>
            <div style={styles.buttonContainer}>
              <button type="submit" style={styles.button} disabled={loading}>
              {loading ? "Uploading..." : "Submit"}              </button>
              <button
                type="button"
                onClick={resetForm}
                style={styles.resetButton}
              >
                Reset Form
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Edit Product"
          style={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={styles.heading}>Edit Product</h2>
            <button
              onClick={closeEditModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "5px",
              }}
            >
              ×
            </button>
          </div>
          <form onSubmit={handleEditSubmit} style={styles.form}>
            <div style={styles.formGroup}>
              <label htmlFor="productName" style={styles.label}>
                Product Name
              </label>
              <input
                type="text"
                id="productName"
                value={productToEdit?.productname}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    productname: event.target.value,
                  })
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="orderType">Order Type:</label>
              <select
                id="orderType"
                value={orderType}
                onChange={handleOrderTypeChange}
              >
                <option value="" disabled>
                  Select an option
                </option>
                <option value="Dinein" style={{
      backgroundColor: productToEdit?.options === "Dinein" ? "green" : "", 
    }}>Dine In</option>
                <option value="Takeaway" style={{
      backgroundColor: productToEdit?.options === "Takeaway" ? "green" : "", 
    }}>Take Away</option>
                <option     style={{
      backgroundColor: productToEdit?.options === "Both" ? "green" : "", 
    }}value="Both">Both</option>
              </select>
  
            </div>

            <div style={styles.formGroup}>
              <label htmlFor="category" style={styles.label}>
                Category
              </label>
              <select
                id="category"
                name="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                style={styles.select}
              >
                <option value="">Select a category</option>
                {categories.map((category) => (
                  <option key={category._id} style={{
                    backgroundColor: productToEdit?.category?._id === category._id ? "green" : "", 
                  }} value={category._id}>
                    {category?.category}
                  </option>
                ))}
              </select>
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="subcategory" style={styles.label}>
                Subcategory
              </label>
              <select
                id="subcategory"
                name="subcategory"
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                style={styles.select}
              >
                <option value="">Select a subcategory</option>
                {Array.isArray(subCategories) &&
                  subCategories.map((subCategory) => (
                    <option key={subCategory._id} value={subCategory._id} style={{
                      backgroundColor: productToEdit?.subcategory?._id === subCategory?._id ? "green" : "", 
                    }}>
                      {subCategory?.subcategory}
                    </option>
                  ))}
              </select>
            </div>
            <label htmlFor="orderType">Bulkorder:</label>

<select
  id="bulkorder"
  value={bulkorder}
  onChange={handlebulkorderChange}
>
  <option value="" disabled>
    Select an option
  </option>
  <option value="Yes" style={{
      backgroundColor: productToEdit?.bulkorder === "Yes" ? "green" : "", 
    }}>Yes</option>
  <option value="No" style={{
      backgroundColor: productToEdit?.bulkorder === "No" ? "green" : "", 
    }}>No</option>
</select>
{bulkorder === "Yes" && (
  <div>
    <label htmlFor="minQuantity">Minimum Quantity:</label>
    <input
      type="number"
      id="minQuantity"
      value={minQuantity}
      onChange={handleMinQuantityChange}
      min="1"
      required
    />
  </div>
)}
            <div style={styles.formGroup}>
              <label htmlFor="productDescription" style={styles.label}>
                Product Description
              </label>
              <textarea
                id="productDescription"
                value={productToEdit?.description}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    description: event.target.value,
                  })
                }
                style={styles.textarea}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productPrice" style={styles.label}>
                Product Price
              </label>
              <input
                type="number"
                id="productPrice"
                value={productToEdit?.price}
                onChange={(event) =>
                  setProductToEdit({
                    ...productToEdit,
                    price: event.target.value,
                  })
                }
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productImages" style={styles.label}>
                Product Images
              </label>
              <input
                type="file"
                id="productImages"
                onChange={handleImageChange}
                multiple
                style={styles.fileInput}
              />
            </div>
            <img
                    src={ productToEdit?.image?.[0] }
                   
                    style={{ width: 25, marginLeft: 10 }}
                    />
            <div style={styles.imagePreviewContainer}>
              {previewImages?.map((src, index) => (
                <div key={index} style={{ position: "relative" }}>
                  <img
                    src={src}
                    alt={`Preview ${index}`}
                    style={styles.imagePreview}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const updatedPreviews = [...previewImages];
                      updatedPreviews.splice(index, 1);
                      setPreviewImages(updatedPreviews);
                    }}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "none",
                      border: "none",
                      color: "#fff",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div>
              <h3>Select Addon Status</h3>
              <label>
                <input
                  type="radio"
                  name="addon"
                  value="true"
                  checked={isAddon === true}
                  onChange={handleRadioChange}
                />
                Yes (Addon is True)
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="addon"
                  value="false"
                  checked={isAddon === false}
                  onChange={handleRadioChange}
                />
                No (Addon is False)
              </label>
            </div>
            {isAddon === true ? (
              <>
                <label htmlFor="productName" style={styles.label}>
                  Enter Addon HEADING
                </label>
                <input
                  type="text"
                  id="setADDON"
                  value={ADDON}
                  onChange={(e) => setADDON(e.target.value)}
                  required
                  style={styles.input}
                />
                {variations?.map((item) => (
                  <div key={item._id}>
                    <input
                      type="checkbox"
                      id={`item-${item._id}`}
                      checked={item.selected}
                      onChange={() => handleSelectionChange(item._id)}
                      style={styles.checkbox}
                    />
                    <label htmlFor={`item-${item._id}`} style={styles.label}>
                      {item?.variationname}
                    </label>
                    {item?.selected && (
                      <>
                        <input
                          type="text"
                          placeholder="Enter price"
                          value={item?.price}
                          onChange={(e) =>
                            handlePriceChange(item._id, e.target.value)
                          }
                          style={styles.input}
                        />
                        {item?.attributes?.map((attribute) => (
                          <div
                            key={attribute._id}
                            style={styles.attributeContainer}
                          >
                            <input
                              type="checkbox"
                              id={`attribute-${attribute._id}`}
                              checked={attribute.selected}
                              onChange={() =>
                                handleAttributeSelectionChange(
                                  item._id,
                                  attribute._id
                                )
                              }
                              style={styles.checkbox}
                            />
                            <label
                              htmlFor={`attribute-${attribute._id}`}
                              style={styles.label}
                            >
                              {attribute?.name}
                            </label>
                            {attribute?.selected && (
                              <input
                                type="text"
                                placeholder="Enter price"
                                value={attribute?.price}
                                onChange={(e) =>
                                  handleAttributePriceChange(
                                    item._id,
                                    attribute._id,
                                    e.target.value
                                  )
                                }
                                style={styles.input}
                              />
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
            <div>
              <h3>Select Funtoppins Status</h3>
              <label>
                <input
                  type="radio"
                  name="funtoppins"
                  value="true"
                  checked={funtoppins === true}
                  onChange={handlefuntoppinsRadioChange}
                />
                Yes (Addon is True)
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input
                  type="radio"
                  name="funtoppins"
                  value="false"
                  checked={funtoppins === false}
                  onChange={handlefuntoppinsRadioChange}
                />
                No (Addon is False)
              </label>
            </div>
            <>
              {funtoppins === true ? (
                <>
                  <label htmlFor="productName" style={styles.label}>
                    Enter Funtoppins HEADING
                  </label>
                  <input
                    type="text"
                    id="funtoppinsheading"
                    value={funtoppinsheading}
                    onChange={(e) => setfuntoppinsheading(e.target.value)}
                    required
                    style={styles.input}
                  />
                  {funtoppinsList.map((topping, index) => (
                    <div key={index} style={{ marginBottom: "10px" }}>
                      <label>
                        Topping Name:
                        <input
                          type="text"
                          name="name"
                          value={topping.name}
                          onChange={(e) => handleInputChange(index, e)}
                          placeholder="Enter topping name"
                        />
                      </label>
                      <div className="product-Topping-Price">
                        <label style={{ marginLeft: "10px" }}>
                          Topping Price:
                          <input
                            type="number"
                            name="price"
                            value={topping.price}
                            onChange={(e) => handleInputChange(index, e)}
                            placeholder="Enter topping price"
                          />
                        </label>
                      </div>
                      <div
                        className="product-Topping-Image"
                        style={{ marginTop: "10px" }}
                      >
                        <label>
                          Topping Image:
                          <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageUpload(index, e)}
                          />
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className="products-Addmore" onClick={handleAddClick}>
                    Add More Topping
                  </div>
                </>
              ) : (
                <>
                  <p>No toppings available.</p>
                </>
              )}
            </>
            <div style={styles.buttonContainer}>
              <button type="submit" style={styles.button} disabled={loading}>
                Update Product
              </button>
              <button
                type="button"
                onClick={resetForm}
                style={styles.resetButton}
              >
                Reset Form
              </button>
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={offermodalIsOpen}
          onRequestClose={closeofferModal}
          contentLabel="Edit Product"
          style={modalStyles}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={styles.heading}>Edit Offer</h2>
            <button
              onClick={closeofferModal}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                fontSize: "20px",
                padding: "5px",
              }}
            >
              ×
            </button>
          </div>
          <form onSubmit={handleofferSubmit} style={styles.form}>
           

            <div style={styles.formGroup}>
              <label htmlFor="productPrice" style={styles.label}>
                Discount
              </label>
              <input
                type="number"
                id="discount"
                value={discount}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value >= 0) {
                    setDiscount(value);
                  }
                }}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="productPrice" style={styles.label}>
                Description
              </label>
              <input
                id="offerdescription"
                value={offerdescription}
                onChange={(e) => {
                  const value = e.target.value;
                 
                    setofferdescription(value);
                  
                }}
                style={styles.input}
              />
            </div>
            <div style={styles.formGroup}>
    <label htmlFor="validStart" style={styles.label}>
      Valid Start
    </label>
    <input
      type="date"
      id="validStart"
      value={validStart}
      onChange={(e) => setValidStart(e.target.value)}
      style={styles.input}
    />
  </div>
  <div style={styles.formGroup}>
    <label htmlFor="validEnd" style={styles.label}>
      Valid End
    </label>
    <input
      type="date"
      id="validEnd"
      value={validEnd}
      onChange={(e) => setValidEnd(e.target.value)}
      style={styles.input}
    />
  </div>

            <div style={styles.buttonContainer}>
              <button type="submit" style={styles.button} disabled={loading}>
                Update Product
              </button>
              <button
                type="button"
                onClick={resetForm}
                style={styles.resetButton}
              >
                Reset Form
              </button>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={viewModalIsOpen}
          onRequestClose={() => setViewModalIsOpen(false)}
          style={customStyles}
          contentLabel="View Product Details"
          ariaHideApp={false}
        >
          <h2>Product Details</h2>
          {selectedProduct && (
            <div>
              {selectedProduct?.image?.map((item, index) => (
                <React.Fragment key={index}>
                  <img
                    src={item}
                    alt={`Product Image ${index}`}
                    style={{ width: "50px" }}
                  />
                </React.Fragment>
              ))}

              <p>Category: {selectedProduct?.category?.category}</p>
              <p>Subcategory: {selectedProduct?.subcategory?.subcategory}</p>
              <p>Product-name: {selectedProduct?.productname}</p>
              <p>Description: {selectedProduct?.description}</p>
              <p>Price: {selectedProduct?.price}</p>
              <p>Bulkorder: {selectedProduct?.bulkorder}</p>
              <p>Min-Quantity: {selectedProduct?.minquantity||""}</p>

              <p>Variation Name:</p>
              {selectedProduct?.variations?.map((item, index) => (
                <p>{item?.variationname}</p>
              ))}
{selectedProduct?.variations?.length > 0 ? (
  <div>
    <p>Variations and Attributes:</p>
    <ul>
      {selectedProduct.variations.map((variation, varIndex) => (
        <li key={varIndex}>
          <strong>Variation Name: {variation.variationname}</strong>
          <p>Price: ${variation.price}</p>
          {variation.attributes?.length > 0 ? (
            <ul>
              {variation.attributes.map((attribute, attrIndex) => (
                <li key={attrIndex}>
                  Attribute: {attribute.name}, Price: ${attribute.price}
                </li>
              ))}
            </ul>
          ) : (
            <p>No attributes found for this variation.</p>
          )}
        </li>
      ))}
    </ul>
  </div>
) : (
  <p>No variations or attributes found.</p>
)}


            </div>
          )}
          <button onClick={() => setViewModalIsOpen(false)}>Close</button>
        </Modal>
        <ToastContainer />
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: "100%",
    margin: "0 auto",
    padding: "20px",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    minHeight: "100vh",
    position: "relative",
    marginLeft: "300px",
  },
  heading: {
    textAlign: "center",
    color: "#333",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formGroup: {
    marginBottom: "10px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#555",
  },
  input: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
  },
  textarea: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    minHeight: "80px",
    width: "100%",
  },
  select: {
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    width: "100%",
  },
  fileInput: {
    padding: "8px",
    width: "100%",
  },
  imagePreviewContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    marginBottom: "20px", // Increased bottom margin to create space between images and buttons
  },
  imagePreview: {
    width: "80px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "8px 16px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginRight: "10px",
  },
  resetButton: {
    padding: "8px 16px",
    backgroundColor: "#DC3545",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
  addButton: {
    padding: "8px 16px",
    backgroundColor: "#28a745",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  checkbox: {
    marginRight: "10px",
  },
};
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "80%",
    maxWidth: "500px",
    fontfamily: "Fraunces",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "#f9fde5",
    border: "none",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent overlay background
  },
};
const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
    width: "90%",
    padding: "15px",
    borderRadius: "8px",
    overflowY: "auto",
    maxHeight: "80vh",
  },
};

export default Products;
