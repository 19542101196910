import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";
import "../Category/Category.css";

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent overlay background
  },
};

const FuntoppinsHeading = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [funtoppins, setFuntoppins] = useState("");
  const [price, setPrice] = useState("");

  const [data, setData] = useState([]);
  const [allfuntoppins, setAllFuntoppins] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
      setNewImage(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}/api/funtoppings/heading`,
        { category: selectedCategory, funtoppinsheading: funtoppins},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data, 'Category');
      toast.success("Funtopping Added");
      setFuntoppins("");
      setNewPreviewImage("");
      setNewImage(null);
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error("Error adding funtopping");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      setLoadingCategories(true);
      const [categoriesResponse, funtoppinsResponse] = await Promise.all([
        axios.get(`${baseUrl}/api/all`),
        axios.get(`${baseUrl}/api/funtoppings/allheading`),
      ]);
      setData(categoriesResponse.data);
      setAllFuntoppins(funtoppinsResponse.data);
      setLoadingCategories(false);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
      setLoadingCategories(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/funtoppings/heading/${id}`);
      console.log(response.data, 'Deleted');
      toast.success("Funtopping Deleted");
      setAllFuntoppins(allfuntoppins.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting funtopping");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setFuntoppins("");
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
     

     

      const response = await axios.put(`${baseUrl}/api/funtoppings/edit/${editCategory._id}`, {
        category: selectedCategory,
        funtoppinsheading: funtoppins
      });

      console.log(response.data, "funtoppings Updated");
      toast.success("Funtopping Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating funtopping");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    try {
      const response = await axios.put(`${baseUrl}/api/funtoppings/onoff/heading/${id}`, {
        "onoff": value
      });
      console.log(response.data);
      fetchDetails();
    } catch (error) {
      console.error('Error toggling funtopping', error);
    }
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {loadingCategories ? (
          <p>Loading...</p>
        ) : allfuntoppins?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No funtoppings available.
          </p>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>Category</th>
                <th style={tableHeaderStyle}>Funtoppins-heading</th>
             

                <th style={tableHeaderStyle}>ON/OFF</th>
                <th style={tableHeaderStyle}>Edit</th>
                <th style={tableHeaderStyle}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {allfuntoppins.map((item) => (
                <tr key={item._id}>
                  <td style={tableCellStyle}>{item?.category?.category}</td>
                  <td style={tableCellStyle}>{item?.funtoppinsheading}</td>

                  <td style={tableCellStyle}>
                    <button
                      style={{ backgroundColor: item.onoff === "ON" ? 'green' : 'red' }}
                      onClick={() => handleToggleClick(item.onoff === "ON" ? 'OFF' : 'ON', item._id)}
                    >
                      {item.onoff}
                    </button>
                  </td>
                  <td style={tableCellStyle}>
                    <button onClick={() => openModal(item)}>Edit</button>
                  </td>
                  <td style={tableCellStyle}>
                    <button onClick={() => handleDelete(item._id)}>Delete</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <button onClick={openAddModal} className="fab-button">Add Funtoppinsheading</button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Funtopping"
        ariaHideApp={false}
      >
        <h2>Edit Funtopping</h2>
        <form onSubmit={handleEditSubmit}>
        <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <option value="">Select a category</option>
            {data?.map((category) => (
              <option key={category._id} value={category._id}>
                {category?.category}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Enter funtoppins"
            id="funtoppins"
            name="funtoppins"
            value={funtoppins}
            onChange={(event) => setFuntoppins(event.target.value)}
          />
         
          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </button>
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        style={customStyles}
        contentLabel="Add Funtopping"
        ariaHideApp={false}
      >
        <h2>Add Funtopping-heading</h2>
        <form onSubmit={handleSubmit}>
          <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={(event) => setSelectedCategory(event.target.value)}
          >
            <option value="">Select a category</option>
            {data?.map((category) => (
              <option key={category._id} value={category._id}>
                {category?.category}
              </option>
            ))}
          </select>
        
          <input
            type="text"
            placeholder="Enter funtoppins heading"
            id="funtoppins"
            name="funtoppins"
            value={funtoppins}
            onChange={(event) => setFuntoppins(event.target.value)}
          />
         
          <button type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Submit"}
          </button>
          <button type="button" onClick={closeAddModal}>
            Close
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default FuntoppinsHeading;

const tableHeaderStyle = {
  padding: "10px",
  backgroundColor: "#f2f2f2",
  borderBottom: "1px solid #ddd",
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};
