import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../Components/Navbar";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Modal from "react-modal";

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.9)", 
    border: "none",
    borderRadius: "10px",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", 
  },
};

const Aboutus = () => {
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [editCategory, setEditCategory] = useState({});
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setPreviewImage(null);
    }
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const title = document.getElementById("category").value;
      const description = document.getElementById("description").value;

      const response = await axios.post(
        `${baseUrl}/api/blog/aboutus`,
        {
          title,
          description,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data, "Contactus");
      toast.success("Aboutus Added");
      setPreviewImage(null);
      setImage(null);
      setCategory("");
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error("Error");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      setLoadingCategories(true);
      const response = await axios.get(`${baseUrl}/api/blog/aboutus`);
      console.log(response.data, "kk");
      setData(response.data);
      setLoadingCategories(false);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
      setLoadingCategories(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/api/blog/about/${id}`);
      console.log(response.data, "Deleted");
      toast.success(" Deleted");
      setData(data.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting category");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setPreviewImage("");
    setImage(null);
    setCategory("");
  };

  const handleNewImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
    } else {
      setNewPreviewImage(null);
    }
    setNewImage(file);
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const response = await axios.put(
        `${baseUrl}/api/blog/aboutus/${editCategory._id}`,
        {
          title: editCategory?.title,
          description: editCategory?.description,
        }
      );

      console.log(response.data, "Updated");
      toast.success("Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating category");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    console.log(value, "value");
    try {
      const response = await axios.put(
        `${baseUrl}/api/blog/aboutus/onoff/${id}`,
        {
          onoff: value,
        }
      );
      console.log(response.data);
      fetchDetails();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {loadingCategories ? (
          <p>Loading...</p>
        ) : data?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No data available.
          </p>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Title
                </th>

                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Description
                </th>

                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  ON/OFF
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Edit
                </th>
                <th
                  style={{
                    padding: "10px",
                    backgroundColor: "#f2f2f2",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  Delete
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item._id} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "10px" }}>{item?.title}</td>

                  <td style={{ padding: "10px" }}>{item?.description}</td>

                  {item?.onoff === "ON" ? (
                    <>
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "green" }}
                          onClick={() => handleToggleClick("OFF", item?._id)}
                        >
                          ON
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ padding: "10px" }}>
                        <button
                          style={{ backgroundColor: "red" }}
                          onClick={() => handleToggleClick("ON", item?._id)}
                        >
                          OFF
                        </button>
                      </td>
                    </>
                  )}
                  <td style={{ padding: "10px" }}>
                    <button onClick={() => openModal(item)}>Edit</button>
                  </td>
                  <td style={{ padding: "10px" }}>
                    <button
                    className="delete-button" onClick={() => handleDelete(item?._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <button onClick={openAddModal} className="fab-button">
          Add About
        </button>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Edit Category"
        ariaHideApp={false}
      >
        <h2>Edit Term</h2>
        <form onSubmit={handleEditSubmit}>
          <input
            type="text"
            placeholder="Enter title"
            id="editCategory"
            name="editCategory"
            value={editCategory.title}
            onChange={(event) =>
              setEditCategory({ ...editCategory, title: event.target.value })
            }
          />

          <input
            type="text"
            placeholder="Enter description"
            id="editCategory"
            name="editCategory"
            value={editCategory.description}
            onChange={(event) =>
              setEditCategory({
                ...editCategory,
                description: event.target.value,
              })
            }
          />

          <button type="submit" disabled={loading}>
            {loading ? "Updating..." : "Update"}
          </button>
          <button type="button" onClick={closeModal}>
            Close
          </button>
        </form>
      </Modal>

      <Modal
        isOpen={addModalIsOpen}
        onRequestClose={closeAddModal}
        style={customStyles}
        contentLabel="Add About"
        ariaHideApp={false}
      >
        <h2>Add About</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Enter Title"
            id="category"
            name="category"
            value={category}
            onChange={(event) => setCategory(event.target.value)}
          />

          <input
            type="text"
            placeholder="Enter Description"
            id="description"
            name="description"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
<div className="add-category-btn-sec">

          <button className="add-cat-subt-btn"  type="submit" disabled={loading}>
            {loading ? "Uploading..." : "Submit"}
          </button>
          <button className="add-cat-close-btn" type="button" onClick={closeAddModal}>
            Close
          </button>
</div>
        </form>
      </Modal>
    </div>
  );
};

export default Aboutus;
