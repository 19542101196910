import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../Components/Navbar";
import Modal from "react-modal";
import "../Category/Category.css";

const baseUrl = process.env.REACT_APP_BASEURL;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.9)",
    border: "none",
    borderRadius: "10px",
    padding: "20px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const Bulkorder = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [funtoppins, setFuntoppins] = useState("");
  const [price, setPrice] = useState("");

  const [data, setData] = useState([]);
  const [allfuntoppins, setAllFuntoppins] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [viewModalIsOpen, setViewModalIsOpen] = useState(false); // New state for view popup
  const [editCategory, setEditCategory] = useState({});
  const [viewData, setViewData] = useState({}); // New state for data to view
  const [newImage, setNewImage] = useState(null);
  const [newPreviewImage, setNewPreviewImage] = useState("");
  const [status, setStatus] = useState("Pending");

  useEffect(() => {
    fetchDetails();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setNewPreviewImage(reader.result);
      });
      reader.readAsDataURL(file);
      setNewImage(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}/api/variation/heading`,
        { category: selectedCategory, variationheading: funtoppins },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data, "Category");
      toast.success("Variation heading Added");
      setFuntoppins("");
      setNewPreviewImage("");
      setNewImage(null);
      setLoading(false);
      fetchDetails();
      closeAddModal();
    } catch (error) {
      console.error(error);
      toast.error("Error adding funtopping");
      setLoading(false);
    }
  };

  const fetchDetails = async () => {
    try {
      setLoadingCategories(true);
      const response = await axios.get(`${baseUrl}/api/products/all/bulkorderform`);

      setData(response.data);
      setAllFuntoppins(response.data);
      setLoadingCategories(false);
    } catch (error) {
      console.error("Network error:", error);
      toast.error("Network error: Unable to fetch details");
      setLoadingCategories(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `${baseUrl}/api/variation/heading/${id}`
      );
      console.log(response.data, "Deleted");
      toast.success("variation heading Deleted");
      setAllFuntoppins(allfuntoppins.filter((item) => item._id !== id));
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting funtopping");
    }
  };

  const openModal = (category) => {
    setEditCategory(category);
    setNewPreviewImage(category.image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setEditCategory({});
  };

  const openAddModal = () => {
    setAddModalIsOpen(true);
  };

  const closeAddModal = () => {
    setAddModalIsOpen(false);
    setNewPreviewImage("");
    setNewImage(null);
    setFuntoppins("");
  };

  const openViewModal = (item) => {
    setViewData(item);
    setViewModalIsOpen(true);
  };

  const closeViewModal = () => {
    setViewModalIsOpen(false);
    setViewData({});
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);

      const response = await axios.put(
        `${baseUrl}/api/variation/edit/heading/${editCategory._id}`,
        {
          category: selectedCategory,
          variationheading: funtoppins,
        }
      );

      console.log(response.data, "variationheading Updated");
      toast.success("Funtopping Updated");
      setLoading(false);
      closeModal();
      fetchDetails();
    } catch (error) {
      console.error(error);
      toast.error("Error updating funtopping");
      setLoading(false);
    }
  };

  const handleToggleClick = async (value, id) => {
    try {
      const response = await axios.put(
        `${baseUrl}/api/variation/heading/onoff/${id}`,
        {
          onoff: value,
        }
      );
      console.log(response.data);
      fetchDetails();
    } catch (error) {
      console.error("Error toggling funtopping", error);
    }
  };

  const handleStatusChange = async (status,id) => {
    console.log();
    
    setStatus(status);
    const response = await axios.put(`${baseUrl}/api/products/bulkorderstatus/${id}`,{
      orderStatus:status
    });
    fetchDetails();
  };

  return (
    <div className="categorymaindiv">
      <Navbar />
      <div className="category-page">
        <ToastContainer className="toast-container" />
        {loadingCategories ? (
          <p>Loading...</p>
        ) : allfuntoppins?.length === 0 ? (
          <p style={{ marginLeft: "150px", color: "red", fontSize: "18px" }}>
            No funtoppings available.
          </p>
        ) : (
          <table style={{ borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={tableHeaderStyle}>View</th>
                <th style={tableHeaderStyle}>Status</th>
                <th style={tableHeaderStyle}>Action</th>

              </tr>
            </thead>
            <tbody>
            {allfuntoppins?.map((item) => (
                <tr key={item._id}>
                  {item?.items?.map((product,index)=>(

                  <td style={tableCellStyle}>{product?.productId?.productname}</td>
                  ))}

                  <td style={tableCellStyle}>
                    <button onClick={() => openViewModal(item)}>View</button>
                  </td>
                  <th style={tableHeaderStyle}>{item?.orderStatus}</th>
                  <th style={tableHeaderStyle}>  <label htmlFor="status">Update Status: </label>
      <select id="status" value={status} onChange={(e)=>{handleStatusChange(e.target.value,item?._id)}}>
        <option value="Pending">Pending</option>
        <option value="Confirmed">Confirmed</option>
        <option value="Delivered">Delivered</option>
      </select></th>

                </tr>

              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* View Modal */}
      <Modal
        isOpen={viewModalIsOpen}
        onRequestClose={closeViewModal}
        style={customStyles}
        contentLabel="View Details"
      >
        <h2>Details</h2>
        <p>Name: {viewData?.name}</p>
        <p>Email: {viewData?.email}</p>
        <p>Order Details: {viewData?.details}</p>
<p>Products:</p>
        {viewData?.products?.map((item,index)=>(
            <>
<p>{item?.product}- Quantity:{item?.quantity}- QuantityType:{item?.quantityType}</p>
            </>
        ))}

        <button onClick={closeViewModal}>Close</button>
      </Modal>
    </div>
  );
};

export default Bulkorder;

const tableHeaderStyle = {
  padding: "10px",
  backgroundColor: "#f2f2f2",
  borderBottom: "1px solid #ddd",
};

const tableCellStyle = {
  padding: "10px",
  borderBottom: "1px solid #ddd",
};
