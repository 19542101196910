import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { storage } from "../Firebase/Config";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from "../../Components/Navbar";
import "./Blog.css";

const baseUrl = process.env.REACT_APP_BASEURL;

const Gallary = () => {
  const [blogs, setBlogs] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/blog/all/gallery`);
      if (Array.isArray(response.data)) {
        setBlogs(response.data);
      } else {
        toast.error("Error fetching blogs");
      }
    } catch (error) {
      toast.error("Error fetching blogs");
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);
    const previews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prevPreviews) => [...prevPreviews, ...previews]);
  };

  const removePreviewImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);

    const updatedPreviews = [...previewImages];
    updatedPreviews.splice(index, 1);
    setPreviewImages(updatedPreviews);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const imageUrls = await Promise.all(
        images.map(async (image) => {
          const imageName = `${uuidv4()}_${image.name}`;
          const imageRef = ref(storage, `images/${imageName}`);
          await uploadBytes(imageRef, image);
          const imageUrl = await getDownloadURL(imageRef);
          return imageUrl;
        })
      );

      const formData = {
        title: productName,

        images: imageUrls,
      };

      const response = await axios.post(
        `${baseUrl}/api/blog/gallery`,
        formData
      );
      toast.success("Created");

      setPreviewImages([]);
      setImages([]);
      setProductName("");
      setProductDescription("");
      setModalIsOpen(false);
      setBlogs((prevBlogs) => [...prevBlogs, response.data]);
    } catch (error) {
      toast.error("Error creating blog");
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleToggleClick = async (value, id) => {
    try {
      await axios.put(`${baseUrl}/api/blog/gallery/onoff/${id}`, { onoff: value });
      fetchBlogs();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };

  const handleTrendingClick = async (value, id) => {
    try {
      await axios.put(`${baseUrl}/api/blog/${id}/trending`, { value });
      fetchBlogs();
    } catch (error) {
      console.error("Error toggling category", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${baseUrl}/api/blog/gallery/${id}`);
      toast.success("Deleted");
      fetchBlogs();
    } catch (error) {
      toast.error("Error");
    }
  };

  return (
    <div className="blog-container">
      <Navbar />
      <div className="blog-content">
        <h1 className="blog-heading">Gallery</h1>
        <button className="create-button" onClick={openModal}>
          Create Gallery
        </button>

        {blogs.length === 0 ? (
          <p className="no-data">No data available.</p>
        ) : (
          <table className="blog-table">
            <thead>
              <tr>
                <th>Type</th>
                
                <th>Image</th>
                <th>ON/OFF</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {blogs.map((item) => (
                <tr key={item._id}>
                  <td>{item.title}</td>
                 
                  <td>
                    <img src={item.image} alt="Category" />
                  </td>
                  <td>
                    {item.onoff === "ON" ? (
                      <button
                        className="button-on"
                        onClick={() => handleToggleClick("OFF", item._id)}
                      >
                        ON
                      </button>
                    ) : (
                      <button
                        className="button-off"
                        onClick={() => handleToggleClick("ON", item._id)}
                      >
                        OFF
                      </button>
                    )}
                  </td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => openModal(item)}
                    >
                      Edit
                    </button>
                  </td>
                 
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => handleDelete(item._id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Create Blog Modal"
          className="modal-content"
        >
          <h2>Create Blog</h2>
          <form className="blog-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="form-label">Type</label>
              <input
                type="text"
                className="form-input"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label className="form-label">Upload Images</label>
              <input
                type="file"
                className="file-input"
                multiple
                onChange={handleImageChange}
              />
            </div>

            <div className="image-preview-container">
              {previewImages.map((preview, index) => (
                <div key={index} className="image-preview-wrapper">
                  <img src={preview} alt="Preview" className="image-preview" />
                  <button
                    type="button"
                    className="close-button"
                    onClick={() => removePreviewImage(index)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <div className="add-category-btn-sec">
              <button className="add-cat-subt-btn" type="submit">
                Submit
              </button>
              <button
                className="add-cat-close-btn"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Gallary;
